





































import { Component, Vue } from 'vue-property-decorator';

import ModalRedaction from '@/globalComponents/Modal/Modal.vue';

@Component({
  components: {
    ModalRedaction,
  },
})
export default class ModalSuccess extends Vue {
  gotToOtherTheme() {
    this.$router.push({
      name: 'Redaction',
    });
  }

  goToDashboard() {
    this.$router.push({
      name: 'begin',
    });
  }
}

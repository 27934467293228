






















































































import { Component, Vue } from 'vue-property-decorator';

import ExLoading from '@/globalComponents/ExLoading/index.vue';

const COMPONENT_REDACTION_DIGITED = 'RedactionDigited';

@Component({
  components: {
    ExLoading,
  },
})
export default class SendRedactionLoading extends Vue {
  private quantityLoading = 2;

  get hasTime() {
    return Number(this.$store.getters.redactionSubmissionSettings.time || sessionStorage.getItem('timeSendRedaction'));
  }

  get isComponentDigited() {
    const component = this.$store.getters.redactionSubmissionSettings.component || sessionStorage.getItem('componentSendRedaction');

    return component === COMPONENT_REDACTION_DIGITED;
  }
}
